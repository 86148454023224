import React, { Fragment, Component } from 'react'

import Layout from '@/layout'
import SEO from '@/seo'
import Container from '@/container'
import WidgetLoader from '@/widget-loader'
import { loadScript } from 'utils/common'

const Widget = ({ feedKey, articleKey, contestKey }) => (
  <WidgetLoader
    feedKey={feedKey}
    articleKey={articleKey}
    contestKey={contestKey}
  />
)

class HomePage extends Component {
  state = { scriptLoaded: false }

  componentDidMount() {
    loadScript(
      `choicely-widget`,
      `https://cdn.choicely.com/widget.js?t=${Date.now()}`,
      () => this.setState({ scriptLoaded: true })
    )
  }

  render() {
    if (!this.state.scriptLoaded) {
      return null
    }

    return (
      <Fragment>
        <SEO title="Tervetuloa Miss Satakunnan yleisöäänestykseen" />
        <Layout>
          <Container>
            <Widget contestKey="fd508d05-0688-11ed-a714-e792dd3f312f" />
          </Container>
        </Layout>
      </Fragment>
    )
  }
}

export default HomePage
